import React from 'react'
import '../style/pharm.css'
import styled from 'styled-components'
import PageNav from '../components/homeNav/PageNav'
import Slider from 'react-slick'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'
import Next from '../images/right-arrow.svg'
import Prev from '../images/left-arrow.svg'
import Med from '../components/med/Med'
import Brands from '../components/brands/Brands'
import Footer from '../components/homeNav/footer/Footer'
import Seo from '../components/seo'
import Logo from '../images/Afyabook.svg'
import BotNav from '../components/bottomNav'
import ImageSlider from '../components/ImageSlider';
import ImageSliderMobile from '../components/ImageSliderMobile';


const ProductSlideWrapper = styled.div`
  background: white;
  padding: 1em 1.5em;
  margin: 1em 0;
  width: 97%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 10px;
  box-shadow: 0 0 7px 0 rgb(0 0 0 / 9%);
`
const BrandContainer = styled.div`
  margin: 10px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
const BrandSlideWrapper = styled.div`
  display: inline-block;
  width: 100%;
  background: #fff;
  margin-top: 10px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.14);
  min-height: 150px;
`
const TopButton = styled(Link)`
  box-shadow: 0 1px 2px rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
  border-radius: 8px;
  white-space: nowrap;
  color: #1d9682;
  font-size: 15px;
  outline: 2.5px solid #1d9682;
  border: none;
  cursor: pointer;
  padding: 5px 15px;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  &:hover {
    transition: all 0.2sease-in-out;
    background: #2e4158;
    color: #e9ecef;
    outline: 1px solid #2e4158;
  }
`
const ImageDiv = styled.div`
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
    height: 300px;
    width: 700px;
  }
`
const ImageDivM = styled.div`
  display: block;
  height: 200px;
  width: 350px;

  @media screen and (min-width: 768px) {
    display: none;
  }
`

const CategoryCard = styled.div`
  margin: 1rem;
  padding: 0.5rem;
  background: white;
  min-width: 200px;
  text-align: center;
  border-radius: 10px;
  border: 2px solid gray;
  cursor: pointer;
`

const ImageDivBody = styled.div`
  width: 400px;
  height: 200px;

  @media screen and (min-width: 768px) {
    height: 220px;
  }
`

const ScrollBar = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  color: blue;
  align-items: center;
  justify-content: start;
  overflow: auto;
  margin-top: 10px;

  // &::-webkit-scrollbar {
  //   width: 0;
  // }

  @media (min-width: 768px) {
    margin-top: 80px; 
  }
`

export default function Pharm({ location }) {
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <img
      src={Prev}
      alt="prevArrow"
      {...props}
      style={{ height: '40px', width: '40px' }}
    />
  )

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <img
      src={Next}
      alt="nextArrow"
      {...props}
      style={{ height: '40px', width: '40px' }}
    />
  )
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    lazyLoad: 'anticipated',
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  }

  const data2 = useStaticQuery(graphql`
    query MyQuery {
      allProducts {
        edges {
          node {
            id
            productName
            batchNumber
            category
            sellingPrice
            customBrandName
            expiry
            formulation
            genericName
            packSize
            prescribed
            strength
            supplier
            showProduct
            showPrice
            type
            unit
            productImage
            score
            discount {
              type
              value
            }
            shop {
              name
              physicalAddress {
                county
                street
              }
            }
          }
        }
      }
    }
  `)

  const productsR = data2.allProducts.edges.map(item => {
    return item.node
  })

  //const products = productsR.filter((product)=>product.shop.name.toLowerCase()==="pharmilymed")

  const preferredShop = process.env.GATSBY_PREFERRED_SHOP

  const products = productsR?.sort((a, b) => {
    if (a.shop._id === preferredShop) return -1
    if (b.shop._id === preferredShop) return 1
    return 0
  })

  const isBrowser = typeof window !== 'undefined'

  const goDown = e => {
    const anchor = isBrowser && window.document.querySelector('.topProducts')
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const goDownP = e => {
    const anchor =
      isBrowser && window.document.querySelector('.personalSection')
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const goDownV = e => {
    const anchor = isBrowser && window.document.querySelector('.vitamins')
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
  const goDownM = e => {
    const anchor = isBrowser && window.document.querySelector('.momBaby')
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
  const goDownB = e => {
    const anchor = isBrowser && window.document.querySelector('.beauty')
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const goDownS = e => {
    const anchor = isBrowser && window.document.querySelector('.sexual')
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const goDownC = e => {
    const anchor = isBrowser && window.document.querySelector('.contraceptives')
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const goDownD = e => {
    const anchor = isBrowser && window.document.querySelector('.devices')
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const goDownPharm = e => {
    const anchor = isBrowser && window.document.querySelector('#pharm')
    anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  return (
    <>
      <Seo
        title="Order medication online in Kenya | Afyabook online pharmacies"
        description="Search and connect directly to pharmacies and healthcare providers in your area. Compare medication prices, place orders and upload prescriptions"
        img={Logo}
        keywords="Online Pharmacy,Online Pharmacies in kenya,Online Pharmacy in kenya, Medical suppliers in kenya,Medical suppliers in Nairobi,Pharmaceutical supply chain, retail pharmacies in kenya,retail pharmacies in Nairobi, find doctors in kenya, selfcare, epharmacy in kenya,e pharmacy kenya,24 hour pharmacy nairobi,online pharmacy kenya"
        siteUrl="https://www.afyabook.com/pharmacy"
        canonical="https://www.afyabook.com/pharmacy"
      ></Seo>
      <PageNav />
      <div className="main-content">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '1rem',
          }}
        >
          <ImageDiv>
            <ImageSlider />
          </ImageDiv>
          <ImageDivM>
            <ImageSliderMobile />
          </ImageDivM>
        </div>
        <ScrollBar>
          <CategoryCard onClick={goDown}>Top Products</CategoryCard>
          <CategoryCard onClick={goDownP}>Personal Care</CategoryCard>
          <CategoryCard onClick={goDownV}>Vitamins & Suppliments</CategoryCard>
          <CategoryCard onClick={goDownM}>Mom & Baby</CategoryCard>
          <CategoryCard onClick={goDownB}>Beauty & Skin Care</CategoryCard>
          <CategoryCard onClick={goDownS}>Sexual Health</CategoryCard>
          <CategoryCard onClick={goDownC}>Family Planning</CategoryCard>
          <CategoryCard onClick={goDownD}>Medical devices</CategoryCard>
          <CategoryCard onClick={goDownPharm}>Pharmacies</CategoryCard>
        </ScrollBar>

        <div className="container-fluid" style={{ margin: '0', padding: '0' }}>
          <div className="slideTitle">
            <div className="saleContainer">
              <span className="heading">Offers</span>
            </div>
            <span className="seeall">
              <TopButton to="/offers">More</TopButton>
            </span>
          </div>
          <ProductSlideWrapper>
            <Slider {...settings}>
              {products.map(product => {
                //.sort((a,b)=>a.productName.toLowerCase()<b.productName.toLowerCase()?-1:(a.productName.toLowerCase()>b.productName.toLowerCase()?1:0)).
                return (
                  product.showProduct &&
                  !product.prescribed &&
                  product.category.includes('offers') && (
                    <Med products={product} />
                  )
                )
              })}
            </Slider>
          </ProductSlideWrapper>
        </div>

        <div className="container-fluid" style={{ margin: '0', padding: '0' }}>
          <div className="slideTitle">
            <div className="saleContainer">
              <span className="heading">Top Products</span>
            </div>
            <span className="seeall">
              <TopButton to="/hygiene">More</TopButton>
            </span>
          </div>
          <ProductSlideWrapper className="topProducts">
            <Slider {...settings}>
              {products.map(product => {
                return (
                  product.showProduct &&
                  !product.prescribed &&
                  product.category.includes('hygiene') && (
                    <Med products={product} />
                  )
                )
              })}
            </Slider>
          </ProductSlideWrapper>
        </div>
        <div className="container-fluid" style={{ margin: '0', padding: '0' }}>
          <div className="slideTitle">
            <div className="saleContainer">
              <span className="heading">Personal Care</span>
            </div>
            <span className="seeall">
              <TopButton to="/personal-care">More</TopButton>
            </span>
          </div>
          <ProductSlideWrapper className="personalCare">
            <Slider {...settings}>
              {products.map(product => {
                return (
                  product.showProduct &&
                  !product.prescribed &&
                  product.category.includes('personal care') && (
                    <Med products={product} />
                  )
                )
              })}
            </Slider>
          </ProductSlideWrapper>
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ImageDivBody>
            <img
              src="https://res.cloudinary.com/tripleaim-software/image/upload/v1667843387/afyabook%20images/suppliments_bunner_gbthz2.jpg"
              width="100%"
              height="100%"
            />
          </ImageDivBody>
        </div>

        <div className="container-fluid" style={{ margin: '0', padding: '0' }}>
          <div className="slideTitle">
            <div className="saleContainer">
              <span className="heading">Vitamins & Suppliments</span>
            </div>
            <span className="seeall">
              <TopButton to="/vitamins-&-suppliments">More</TopButton>
            </span>
          </div>
          <ProductSlideWrapper className="vitamins">
            <Slider {...settings}>
              {products.map(product => {
                return (
                  product.showProduct &&
                  !product.prescribed &&
                  product.category.includes('vitamins & suppliments') && (
                    <Med products={product} />
                  )
                )
              })}
            </Slider>
          </ProductSlideWrapper>
        </div>
        <div className="container-fluid" style={{ margin: '0', padding: '0' }}>
          <div className="slideTitle">
            <div className="saleContainer">
              <span className="heading">Beauty & Skin care</span>
            </div>
            <span className="seeall">
              <TopButton to="/beauty-and-skin-care">More</TopButton>
            </span>
          </div>
          <ProductSlideWrapper className="beauty">
            <Slider {...settings}>
              {products.map(product => {
                return (
                  product.showProduct &&
                  !product.prescribed &&
                  product.category.includes('beauty and skin care') && (
                    <Med products={product} />
                  )
                )
              })}
            </Slider>
          </ProductSlideWrapper>
        </div>

        <div className="container-fluid" style={{ margin: '0', padding: '0' }}>
          <div className="slideTitle">
            <div className="saleContainer">
              <span className="heading">Mom and Baby</span>
            </div>
            <span className="seeall">
              <TopButton to="/mum-and-baby">More</TopButton>
            </span>
          </div>
          <ProductSlideWrapper className="momBaby">
            <Slider {...settings}>
              {products.map(product => {
                return (
                  product.showProduct &&
                  !product.prescribed &&
                  product.category.includes('mum and baby') && (
                    <Med products={product} />
                  )
                )
              })}
            </Slider>
          </ProductSlideWrapper>
        </div>

        <div className="container-fluid" style={{ margin: '0', padding: '0' }}>
          <div className="slideTitle">
            <div className="saleContainer">
              <span className="heading">Sexual Health</span>
            </div>
            <span className="seeall">
              <TopButton to="/sexual-health">More</TopButton>
            </span>
          </div>
          <ProductSlideWrapper className="sexual">
            <Slider {...settings}>
              {products.map(product => {
                return (
                  product.showProduct &&
                  !product.prescribed &&
                  product.category.includes('sexual health') && (
                    <Med products={product} />
                  )
                )
              })}
            </Slider>
          </ProductSlideWrapper>
        </div>

        <div className="container-fluid" style={{ margin: '0', padding: '0' }}>
          <div className="slideTitle">
            <div className="saleContainer">
              <span className="heading">Medical devices</span>
            </div>
            <span className="seeall">
              <TopButton to="/medical-devices">More</TopButton>
            </span>
          </div>
          <ProductSlideWrapper className="devices">
            <Slider {...settings}>
              {products.map(product => {
                return (
                  product.showProduct &&
                  !product.prescribed &&
                  product.category.includes('medical devices') && (
                    <Med products={product} />
                  )
                )
              })}
            </Slider>
          </ProductSlideWrapper>
        </div>

        <div className="container-fluid" style={{ margin: '0', padding: '0' }}>
          <div className="slideTitle">
            <div className="saleContainer">
              <span className="heading">Family planning</span>
            </div>
            <span className="seeall">
              <TopButton to="/family-planning">More</TopButton>
            </span>
          </div>
          <ProductSlideWrapper className="contraceptives">
            <Slider {...settings}>
              {products.map(product => {
                return (
                  product.showProduct &&
                  !product.prescribed &&
                  product.category.includes('family planning') && (
                    <Med products={product} />
                  )
                )
              })}
            </Slider>
          </ProductSlideWrapper>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginBottom: '10px',
            width: '100%',
          }}
        >
          <Link to="/userprofile">
            <div style={{ maxWidth: '500px', height: '100%', margin: '10px' }}>
              <img
                src="https://res.cloudinary.com/tripleaim-software/image/upload/v1682074038/Record_and_monitor_your_measurements_now_bjozsh.jpg"
                width="100%"
                style={{ borderRadius: '20px' }}
              />
            </div>
          </Link>
          <Link to="/upload-prescription">
            <div style={{ maxWidth: '500px', height: '100%', margin: '10px' }}>
              <img
                src="https://res.cloudinary.com/tripleaim-software/image/upload/v1682064267/uploads_tondld.png"
                width="100%"
                style={{ borderRadius: '20px' }}
              />
            </div>
          </Link>
        </div>

        {/* <div className="container-fluid" style={{ margin: "0", padding: "0" }}>
          <div className="slideTitle">
            <div className="saleContainer">
              <span className="heading" id="pharm">Pharmacies</span>
            </div>            
          </div>
          <BrandContainer>
            <BrandSlideWrapper>
              <Brands />
            </BrandSlideWrapper>
          </BrandContainer>
        </div> */}
      </div>
      <BotNav />
      <Footer />
    </>
  )
}
